import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

class HomePage extends React.Component {
  renderSection = (title, url, description) => {
    return (
      <div key={title}>
        <h3
          style={{
            marginBottom: rhythm(1 / 4),
          }}
        >
          <Link style={{ boxShadow: `none` }} to={url}>
            {title}
          </Link>
        </h3>
        {description}
      </div>
    )
  }

  render = () => {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    const sections = [
      {
        title: "Photos",
        url: "/photos",
        description: (
          <div>
            <p>
              Here you can see single photos that I share semi-regularly.
              Snapshots of life around me: trips, streets, and landscapes.
            </p>
            <p>
              You can think of it as{" "}
              <Link to="/photos">my Instagram account</Link>.
            </p>
          </div>
        ),
      },
      {
        title: "Daily list",
        url: "https://daily.haikuco.de",
        description: (
          <div>
            <p>
              A list I use to practise my daily habit of putting thoughs to
              paper.
            </p>
            <p>
              <a href="https://daily.haikuco.de">Subscribe</a> to read my
              programming experiences, ideas, mistakes and tips I wish I'd known
              myself earlier. Learn how to enable high-performing teams, make an
              impact, grow as a software engineer and level up your career.
            </p>
          </div>
        ),
      },
      {
        title: "Writing",
        url: "/blog",
        description: (
          <div>
            <p>
              That's where I write my thoughts, essays, and post photos with a
              story.
            </p>
            <p>
              You can think of it as <Link to="/blog">my blog.</Link>
            </p>
          </div>
        ),
      },
      {
        title: "Book notes",
        url: "/books",
        description: (
          <div>
            <p>
              Recently, I've decided to write down notes on some of the books as
              I read them. I found it's a pity that sometimes I read a book and
              only get the immediate takeaways out of that, but anything that
              requires some growth, or simply time, to flourish fades away. And
              I rarely go back to re-read a book, as it superficially seems like
              a waste of time. Maybe I should have, sometimes.
            </p>
            <p>
              The role of book notes is two-fold. Firstly, I want to deepen my
              understanding of the book - taking notes means I can't just skip
              over the passages that are more complicated, or don't make much
              sense on the first look. Secondly, it gives me something to come
              back to when I want to refresh this subject without reading the
              whole book again.
            </p>
          </div>
        ),
      },
    ]

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="All posts"
          keywords={[
            `blog`,
            `photos`,
            `essays`,
            `sharnik`,
            `wojciech ogrodowczyk`,
            `haiku code`,
            `travel`,
            `programming`,
          ]}
        />
        <Bio />
        {sections.map(({ title, url, description }) =>
          this.renderSection(title, url, description)
        )}
      </Layout>
    )
  }
}

export default HomePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
